














import Vue from "vue";
import { mapState } from "vuex";
import OrderProcess from "../components/OrderProcess.vue";
export default Vue.extend({
    name: "Home",
    components: {
        OrderProcess,
    },
    computed: {
        ...mapState("user", ["currentUser"]),
    },
});
